import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {

  constructor(
    public _toastController: ToastController
  ){}

  async presentToast(message, color){
    const toast = await this._toastController.create({
      message: message,
      color: color,
      duration: 2000
    });
    return toast.present();
  }

}