import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Storage } from '@ionic/storage-angular';

@Injectable()
export class DataService {

  private registeredForMulti = new BehaviorSubject(false);
  multiStatus = this.registeredForMulti.asObservable();

  constructor(
    private _storage: Storage
  ){}

  changeMultiStatus(data: boolean){
    this.registeredForMulti.next(data);
  }

  // Storage

  async getPushToken(){
    return await this._storage.get('pushToken');
  }

  async getScanKey(){
    return await this._storage.get('scanKey');
  }

  async getObjectId(){
    return await this._storage.get('objectId');
  }

  async getCity(){
    return await this._storage.get('city');
  }

  async getNearbyCities(){
    return await this._storage.get('nearbyCities');
  }

  async getSelectedLanguage(){
    return await this._storage.get('selectedLanguage');
  }

  async getDeviceIdUuid(){
    return await this._storage.get('UUID');
  }

  async getObjectName(){
    return await this._storage.get('objectName');
  }

}