import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'scan-enter-code',
    pathMatch: 'full'
  },
  {
    path: 'home',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'report-a-bug',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./report-a-bug/report-a-bug.module').then( m => m.ReportABugPageModule)
  },
  {
    path: 'scan-enter-code/:param',
    loadChildren: () => import('./scan-enter-code/scan-enter-code.module').then( m => m.ScanEnterCodePageModule)
  },
  {
    path: 'scan-enter-code',
    loadChildren: () => import('./scan-enter-code/scan-enter-code.module').then( m => m.ScanEnterCodePageModule)
  },
  {
    path: 'multi-object/:id',
    loadChildren: () => import('./multi-object/multi-object.module').then( m => m.MultiObjectPageModule)
  },
  {
    path: 'terms-of-service',
    loadChildren: () => import('./terms-of-service/terms-of-service.module').then( m => m.TermsOfServicePageModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'privacy-policy',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'you-may-need',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./you-may-need/you-may-need.module').then( m => m.YouMayNeedPageModule)
  },
  {
    path: 'welcome',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'pick-your-interest',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pick-your-interest/pick-your-interest.module').then( m => m.PickYourInterestPageModule)
  },
  {
    path: 'pick-your-interest-single',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pick-your-interest-single/pick-your-interest-single.module').then( m => m.PickYourInterestSinglePageModule)
  },
  {
    path: 'transport',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./transport/transport.module').then( m => m.TransportPageModule)
  },
  {
    path: 'emergency-contact',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./emergency-contact/emergency-contact.module').then( m => m.EmergencyContactPageModule)
  },
  {
    path: 'wake-up-call',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./wake-up-call/wake-up-call.module').then( m => m.WakeUpCallPageModule)
  },
  {
    path: 'pick-your-interest-single-details',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pick-your-interest-single-details/pick-your-interest-single-details.module').then( m => m.PickYourInterestSingleDetailsPageModule)
  },
  {
    path: 'you-may-need-single-details',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./you-may-need-single-details/you-may-need-single-details.module').then( m => m.YouMayNeedSingleDetailsPageModule)
  },
  {
    path: 'offer-for-you-list',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./offer-for-you-list/offer-for-you-list.module').then( m => m.OfferForYouListPageModule)
  },
  {
    path: 'offer-for-you-single-details',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./offer-for-you-single-details/offer-for-you-single-details.module').then( m => m.OfferForYouSingleDetailsPageModule)
  },
  {
    path: 'push-notifications',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./push-notifications/push-notifications.module').then( m => m.PushNotificationsPageModule)
  },
  {
    path: 'audio-guide-locations',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./audio-guide-locations/audio-guide-locations.module').then( m => m.AudioGuideLocationsPageModule)
  },
  {
    path: 'audio-guide-points',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./audio-guide-points/audio-guide-points.module').then( m => m.AudioGuidePointsPageModule)
  },
  {
    path: 'audio-guide-point-details',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./audio-guide-point-details/audio-guide-point-details.module').then( m => m.AudioGuidePointDetailsPageModule)
  },
  {
    path: 'viator-places-list',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./viator-places-list/viator-places-list.module').then( m => m.ViatorPlacesListPageModule)
  },
  {
    path: 'viator-offers-list',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./viator-offers-list/viator-offers-list.module').then( m => m.ViatorOffersListPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}