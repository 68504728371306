import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Device } from '@capacitor/device';

import { Platform, AlertController, MenuController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from './services/authentication.service';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  home: any;
  pushTitle: string;
  pushBody: string;
  showMulti: boolean = false;
  
  constructor(
    public _alertController: AlertController,
    private _authService: AuthenticationService,
    private _dataService: DataService,
    private _location: Location,
    public _menuCtrl: MenuController,
    private platform: Platform,
    private _router: Router,
    private _storage: Storage,
    public _translateService: TranslateService
  ){
    this.initStorage();
    this.getDeviceInfo();
    //Provjera izlaska iz aplikacije
    this.platform.backButton.subscribeWithPriority(10, () => {
      if(this._router.url === "/scan-enter-code"){
        this.exitAlertConfirm();
      } else {
        this._location.back();
      }
    });
    // //Multi objects
    this._dataService.multiStatus.subscribe((data) => {
      if(data){
        this.showMulti = data;
      } else {
        this.showMulti = false;
      }
    });
    this.initializeApp();
  }

  async getDeviceInfo() {
    const info = await Device.getId();
    if(info.uuid){
      this._storage.set('UUID', info.uuid);
    }
    //DeviceLanguage
    // const deviceLng = await Device.getLanguageCode();
    // if (deviceLng.value != undefined){
    //     this._storage.set('deviceLng', deviceLng.value);
    //     this._translateService.use(deviceLng.value);
    // } else {
    //     this._storage.set('deviceLng', 'en');
    //     this._translateService.setDefaultLang('en');
    // }
  }

  async initStorage() {
    await this._storage.create();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.getSelectedLanguage();
      this.exitComfirmation();
    });
  }

  getSelectedLanguage(){
    this._storage.get('selectedLanguage').then((val) => {
      if(val){
        this._translateService.use(val);
      } else {
        this._translateService.setDefaultLang('en');
      }
    });
  }

  closeMenu() {
    this._menuCtrl.close();
  }

  async showPush(title,body){
    const alert = await this._alertController.create({
      header: title,
      message: body,
      buttons: [
        {
          text: 'OK',
          cssClass: 'buttonAlert',
          handler: () => {}
        }
      ]
    });
    await alert.present();
  }

  exitComfirmation(){
    this.platform.backButton.subscribeWithPriority(10, () => {
      if(this._router.url === "/home"){
        this.exitAlertConfirm();
      } else {
        this._location.back();
      }
    });
  }

  async exitAlertConfirm() {
    const alert = await this._alertController.create({
      cssClass: 'my-custom-class',
      message: this._translateService.instant('CLOSE-APP-TITLE'),
      buttons: [
        {
          text: this._translateService.instant('SM-NO'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        }, {
          text: this._translateService.instant('SM-YES'),
          handler: () => {
            navigator['app'].exitApp();
          }
        }
      ]
    });
    await alert.present();
  }

  //LogOut
  async presentAlertConfirm() {
    const alert = await this._alertController.create({
      message: this._translateService.instant('SM-LEAVE-DESCRIPTION'),
      buttons: [
        {
          text: this._translateService.instant('SM-YES'),
          cssClass: 'buttonAlert',
          handler: () => {
            this._authService.logout();
            // this._storage.clear();
            this._storage.remove('objectId');
            this._storage.remove('auth-token');
            this._storage.remove('city');
            this._storage.remove('nearbyCities');
            this._storage.remove('objectsList');
            this._storage.remove('selectedLanguage');
            this._router.navigate(['scan-enter-code']);
            this._translateService.use('en');
            this._dataService.changeMultiStatus(false);
          }
        },
        {
          text: this._translateService.instant('SM-NO'),
          role: 'cancel',
          cssClass: 'buttonAlert',
          handler: () => {}
        }
      ]
    });
    await alert.present();
  }

}