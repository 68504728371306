import { NgModule } from "@angular/core";
import { IonicModule } from '@ionic/angular';
import { SafeHtmlPipe } from "./safe-html.pipe";


@NgModule({
  declarations: [SafeHtmlPipe],
  imports: [IonicModule],
  exports: [SafeHtmlPipe]
})
export class PipesModule {}