<ion-app>
  <!-- <ion-split-pane contentId="main-content"> -->
    <ion-menu contentId="main-content" type="overlay" swipeGesture="false">
      <div class="header">
      <!-- <ion-header> -->
          <ion-row>
            <ion-col>
              <img src="assets/logo.svg">
            </ion-col>
            <ion-col class="title">
               GUIDE FOR YOU
            </ion-col>
            <img class="exit" src="assets/exit.png" (click)="closeMenu()">
            <!-- <ion-icon name="close" size="large"></ion-icon> -->
          </ion-row>
      <!-- </ion-header> -->
      </div>
      <ion-content>
        <div class="popis-izbornik">
        <!-- <ion-list lines="none"> -->
          <ion-menu-toggle auto-hide="false">
            <ion-item [routerDirection]="'forward'" [routerLink]="home" lines="none" detail="false">
              <img src="assets/home.png">
              <ion-label>
                {{ 'SM-HOME' | translate }}
              </ion-label>
            </ion-item>
            <ion-item [routerDirection]="'forward'" [routerLink]="['/settings']" lines="none" detail="false">
              <img src="assets/settings.png">
              <ion-label>
                {{ 'SM-SETTINGS' | translate }}
              </ion-label>
            </ion-item>
            <ion-item [routerDirection]="'forward'" [routerLink]="['/multi-object/2']" lines="none" detail="false" *ngIf="showMulti">
              <img src="assets/list.svg">
              <ion-label>
                {{ 'MULTI-OBJECTS' | translate }}
              </ion-label>
            </ion-item>
            <ion-item [routerDirection]="'root'" (click)="presentAlertConfirm()" lines="none" detail="false">
              <img src="assets/leave.png">
              <ion-label>
                {{ 'SM-LEAVE' | translate }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </div>
        <div class="footer">
          <ion-menu-toggle auto-hide="false">
          <ion-item [routerDirection]="'forward'" [routerLink]="['report-a-bug']" lines="none" detail="false">
            <img src="assets/bug.png">
            <ion-label>
              {{ 'SM-REPORT-A-BUG' | translate }}
            </ion-label>
          </ion-item>
          </ion-menu-toggle>
        </div>
        <!-- <div class="bug">
          <img src="assets/bug.png">
        </div> -->
        <!-- </ion-list> -->
        <!-- <ion-list lines="none">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
            <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
              <ion-icon slot="start" [name]="p.icon"></ion-icon>
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list> -->
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  <!-- </ion-split-pane> -->
</ion-app>